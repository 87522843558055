// @flow
import React from 'react';
import _ from 'lodash';

import type { FieldElementDefinition } from '@an/nova-form-components';

import Overlay from '../../../../ui/components/Overlay';
import AddElementForm from '../../formInputs/AddElementForm';
import { fieldTypeStrings, fieldType as getFieldType } from '../../../../helpers/flowRuntimeHelpers/formTypesLookup';

type Props = {
  onClose: () => any,
  visible: boolean,
  addOrUpdateField: (FieldElementDefinition) => any,
  editingField?: FieldElementDefinition,
  existingKeys: string[],
};

type State = {
  fieldType?: string,
};

const defaultState = {
  fieldType: undefined,
};

export default class AddFieldOverlay extends React.Component<Props, State> {
  static defaultProps = {
    editingField: undefined,
  };

  constructor(props: Props, context: any) {
    super(props, context);
    this.state = defaultState;
  }

  render() {
    const { visible } = this.props;
    const fieldType = this.fieldType();

    return (
      <Overlay onClose={this.onClose} visible={visible}>
        {fieldType ? (
          <AddElementForm
            type={getFieldType(fieldType)}
            onSubmit={this.addOrUpdateField}
            initialElementValues={this.props.editingField}
            existingKeys={this.props.existingKeys}
          />
        ) : (
          <div>
            {fieldTypeStrings().map((ft) => (
              <button
                key={ft}
                onClick={() => this.selectFieldType(ft)}
                style={{ display: 'block', margin: '0.5rem 0' }}
                type="button"
              >
                {`Add ${ft}`}
              </button>
            ))}
          </div>
        )}
      </Overlay>
    );
  }

  fieldType = () => {
    const editingFieldType = _.get(this.props.editingField, 'type');
    return editingFieldType || this.state.fieldType;
  };

  onClose = () => {
    this.setState(defaultState);
    this.props.onClose();
  };

  selectFieldType = (type: string) => {
    this.setState({ fieldType: type });
  };

  addOrUpdateField = (field: FieldElementDefinition) => {
    this.setState(defaultState);
    this.props.addOrUpdateField(field);
  };
}
