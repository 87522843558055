// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Dispatch } from 'redux';
import { type ApiError, type FormDefinition, Button } from '@an/nova-form-components';
import Config from '../../../config';
import Overlay from '../../../ui/components/Overlay';
import NewFormForm from '../../components/NewFormForm';
import { createForm, fetchForms } from './HomeScreenActions';
import { PageWrapper } from '../../../ui/components/PageWrapper';
import { getFormsEnvironment } from '../../../helpers/getFormsEnvironment';
import { environments, getIsLocalEnvironment, getIsDevelopmentEnvironment } from '../../../constants/environments';

type Props = {
  fetchForms: () => void,
  createForm: (FormDefinition) => any,
  error: ApiError,
  formCreated: boolean,
  forms: { formId: string }[],
  loading: boolean,
};

type State = {
  showAddFormOverlay: boolean,
};

const mapStateToProps = (state) => ({
  error: state.homeScreen.error,
  formCreated: state.homeScreen.formCreated,
  forms: state.homeScreen.forms,
  loading: state.homeScreen.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  fetchForms: () => dispatch(fetchForms()),
  createForm: (form: FormDefinition) => dispatch(createForm(form)),
});

class HomeScreen extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      showAddFormOverlay: false,
    };
  }

  async componentDidMount() {
    this.props.fetchForms();
  }

  componentDidUpdate(prevProps: Props) {
    const { formCreated } = this.props;
    if (prevProps.formCreated !== formCreated) {
      this.props.fetchForms();
    }
  }

  render() {
    const { forms, error, loading } = this.props;
    const environment = getFormsEnvironment();
    const isDevelopmentEnvironment = getIsDevelopmentEnvironment(environment);
    const isLocalEnvironment = getIsLocalEnvironment(environment);

    return environment ? (
      <PageWrapper environment={environment}>
        <section className="section section__form group">
          <div className="col span_12_of_12">
            <h2>Forms List</h2>
            {loading && <div className="loader">&nbsp;</div>}
            {forms && (
              <ul>
                {forms.map((form) => (
                  <li key={form.formId}>
                    {form.formId}
                    :&nbsp;
                    <Link to={`/forms/${form.formId}`}>Manage</Link>
                    &nbsp;|&nbsp;
                    {isLocalEnvironment ? (
                      <Link to={`/preview/${form.formId}`}>Preview</Link>
                    ) : (
                      <a
                        href={`${environments[Config.environment].frontendUrl}/${form.formId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            )}
            <Overlay
              visible={this.state.showAddFormOverlay}
              onClose={() => this.setState({ showAddFormOverlay: false })}
            >
              <NewFormForm addForm={this.addForm} />
            </Overlay>
            {isDevelopmentEnvironment && (
              <Button
                onClick={() => this.setState({ showAddFormOverlay: true })}
                label="Add Form"
                type="button"
                level="primary"
              />
            )}
            {error && (
              <div className="error-message">
                <h2>Error</h2>
                <div>
                  Code: {error.code} <br />
                  Message: {error.message}
                </div>
              </div>
            )}
          </div>
        </section>
      </PageWrapper>
    ) : null;
  }

  addForm = (form: FormDefinition) => {
    this.setState({ showAddFormOverlay: false });
    this.props.createForm(form);
  };
}

// $FlowFixMe WEB-352
export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
