// @flow
import React from 'react';

import type { ComponentElementDefinition } from '@an/nova-form-components';

import AddComponentOverlay from '../AddComponentOverlay/AddComponentOverlay';
import ComponentRow from './ComponentRow';
import FieldSetRows from './FieldSetRows';

type Props = {
  components: ComponentElementDefinition[],
  addOrUpdateComponent: (component: ComponentElementDefinition) => {},
  removeComponent: (component: ComponentElementDefinition) => {},
  reorderComponent: (component: ComponentElementDefinition, displacement: number) => any,
  existingKeys: string[],
};

type State = {
  showOverlay: boolean,
  editingComponent?: ComponentElementDefinition,
};

class ComponentTable extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      showOverlay: false,
      editingComponent: undefined,
    };
  }

  render() {
    const { editingComponent, showOverlay } = this.state;
    const { components, existingKeys, removeComponent, reorderComponent } = this.props;
    return (
      <div>
        <AddComponentOverlay
          addOrUpdateComponent={this.addOrUpdateComponent}
          onClose={this.hideAddComponent}
          visible={showOverlay}
          existingComponents={components}
          editingComponent={editingComponent}
          existingKeys={existingKeys}
        />
        <h3>Components</h3>

        <table>
          <thead>
            <tr>
              <th>Number</th>
              <th>Key</th>
              <th>Type</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          {components.map((component, cIndex) => {
            if (component.type === 'fieldset') {
              return (
                <FieldSetRows
                  key={component.key}
                  number={cIndex + 1}
                  // $FlowFixMe - fields is missing in ComponentElementDefinition
                  fieldSet={component}
                  onClickEdit={this.handleEditComponentClick(component)}
                  addOrUpdateComponent={this.addOrUpdateComponent}
                  removeComponent={() => removeComponent(component)}
                  reorderComponent={(displacement) => reorderComponent(component, displacement)}
                  existingKeys={existingKeys}
                />
              );
            }
            return (
              <tbody key={component.key}>
                <ComponentRow
                  number={cIndex + 1}
                  component={component}
                  onClickEdit={this.handleEditComponentClick(component)}
                  removeComponent={() => this.props.removeComponent(component)}
                  reorderComponent={(displacement) => this.props.reorderComponent(component, displacement)}
                />
              </tbody>
            );
          })}
          <tbody>
            <tr>
              <td>
                <button onClick={this.showAddComponent} type="button">
                  Add component
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  handleEditComponentClick = (component: ComponentElementDefinition) => () =>
    this.setState({ editingComponent: component, showOverlay: true });

  hideAddComponent = () => this.setState({ showOverlay: false });

  showAddComponent = () => this.setState({ showOverlay: true, editingComponent: undefined });

  addOrUpdateComponent = (component: ComponentElementDefinition) => {
    this.setState({ showOverlay: false });
    this.props.addOrUpdateComponent(component);
  };
}

export default ComponentTable;
