// @flow
import React from 'react';

type Props = {
  value: string,
};

const StringLiteralInput = ({ value }: Props) => <div>{value}</div>;

export default StringLiteralInput;
