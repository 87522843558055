// @flow
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { authReducer, type AuthReducerState } from '@an/nova-frontend-auth';

import homeScreenReducer, { type HomeScreenState } from '../../admin/screens/HomeScreen/HomeScreenReducer';

// TODO-Flow: It should be possible to generate this automatically from the
// reducers as explained in this post:
// https://blog.callstack.io/type-checking-react-and-redux-thunk-with-flow-part-2-206ce5f6e705
export type ReduxState = {
  auth: AuthReducerState,
  homeScreen: HomeScreenState,
};

export default combineReducers<_, *>({
  auth: authReducer,
  form: formReducer,
  homeScreen: homeScreenReducer,
});
