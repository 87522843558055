// @flow
import React from 'react';

import { PageDefinition } from '@an/nova-form-components';
import Overlay from '../../../../ui/components/Overlay';

import PageButton from './PageButton';
import AddElementForm from '../../formInputs/AddElementForm';

type Props = {
  pages: PageDefinition[],
  selectedPage: number,
  onPageSelect: (PageDefinition) => any,
  addPage: (PageDefinition) => any,
  existingKeys: string[],
};

type State = {
  showOverlay: boolean,
};

class PageSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showOverlay: false,
    };
  }

  render() {
    return (
      <div>
        <Overlay onClose={() => this.setState({ showOverlay: false })} visible={this.state.showOverlay}>
          <AddElementForm
            type={PageDefinition}
            onSubmit={this.addPage}
            initialElementValues={{
              page: this.props.pages.length + 1,
              components: [],
            }}
            excludedElementKeys={['components']}
            readonlyElementKeys={['page']}
            existingKeys={this.props.existingKeys}
          />
        </Overlay>
        {this.props.pages.map((p) => (
          <PageButton
            key={`page-${p.page}`}
            page={p}
            active={p.page === this.props.selectedPage}
            onPageSelect={this.props.onPageSelect}
          />
        ))}
        <button onClick={() => this.setState({ showOverlay: true })} style={{ display: 'inline-block' }} type="button">
          Add page
        </button>
      </div>
    );
  }

  addPage = (page: $Shape<PageDefinition>) => {
    this.setState({ showOverlay: false });
    this.props.addPage(page);
  };
}

export default PageSelector;
