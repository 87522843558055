// @flow
import type { ApiError, FormDefinition } from '@an/nova-form-components';
import { Actions, type HomeScreenAction } from './HomeScreenActions';

export type HomeScreenState = {|
  formCreated?: boolean,
  forms?: FormDefinition[],
  error?: $Shape<ApiError>,
  loading?: boolean,
|};

const initialState: HomeScreenState = {
  error: undefined,
  formCreated: false,
  forms: undefined,
  loading: true,
};

// eslint-disable-next-line default-param-last
export default (state: HomeScreenState = initialState, action: HomeScreenAction): HomeScreenState => {
  switch (action.type) {
    case Actions.CREATE_FORM_REQUEST:
    case Actions.FETCH_FORMS_REQUEST:
      return initialState;
    case Actions.FETCH_FORMS_SUCCESS:
      return {
        ...state,
        formCreated: false,
        forms: action.payload,
        error: undefined,
        loading: false,
      };
    case Actions.CREATE_FORM_SUCCESS:
      return {
        ...state,
        formCreated: true,
        error: undefined,
        loading: false,
      };
    case Actions.CREATE_FORM_ERROR:
    case Actions.FETCH_FORMS_ERROR:
      return {
        ...state,
        formCreated: false,
        error: {
          code: action.response.status,
          message: action.payload,
        },
        loading: false,
      };
    default:
      return state;
  }
};
