// @flow
import React from 'react';
import _ from 'lodash';
import { ObjectTypeProperty } from 'flow-runtime';
import Overlay from '../../../../ui/components/Overlay';
// eslint-disable-next-line import/no-cycle
import AddElementForm from '../AddElementForm';
import ConfirmDialogButton from '../../../../ui/ConfirmDialogButton';

import type { ArrayInputItem } from '../types';

type Props = {
  keys: string[],
  element: ArrayInputItem,
  updateElement: (keys: string[], value: string) => any,
  typeProperty: ObjectTypeProperty,
  existingKeys: string[],
  onItemsChange: (ArrayInputItem[], string) => any,
};

type State = {
  showOverlay: boolean,
};

class ArrayInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showOverlay: false,
    };
  }

  render() {
    const { keys, typeProperty, existingKeys } = this.props;
    return (
      <div style={{ backgroundColor: '#0000aa44' }}>
        <Overlay visible={this.state.showOverlay} onClose={() => this.setState({ showOverlay: false })}>
          <AddElementForm type={typeProperty} onSubmit={this.handleSubmit} existingKeys={existingKeys} />
        </Overlay>
        <table>
          <tbody>
            {this.array().map((i) => (
              <tr key={JSON.stringify(i)}>
                <td>{JSON.stringify(i)}</td>
                <td>
                  <button style={{ background: '#11ffff22' }} onClick={() => this.handleReorder(i, -1)} type="button">
                    ↑
                  </button>
                </td>
                <td>
                  <button style={{ background: '#ff111122' }} onClick={() => this.handleReorder(i, 1)} type="button">
                    ↓
                  </button>
                </td>
                <td>
                  <ConfirmDialogButton
                    buttonText="delete"
                    onConfirm={() => this.handleDelete(i)}
                    messageText={`Would you like to DELETE ${this.props.typeProperty.name || 'item'} ${i.key}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button onClick={() => this.setState({ showOverlay: true })} type="button">
          Add {keys.join('.')} {this.props.typeProperty.name || 'Item'}
        </button>
      </div>
    );
  }

  array = () => _.get(this.props.element, this.props.keys.join('.'));

  handleReorder = (element: ArrayInputItem, displacement: number) => {
    const array = this.array();
    const currentIndex = array.indexOf(element);
    const newIndex = currentIndex + displacement;
    if (newIndex > -1 || newIndex < array.length) {
      const removedElement = array.splice(currentIndex, 1)[0];
      array.splice(newIndex, 0, removedElement);
    }
    this.props.onItemsChange(array, this.props.keys.join(''));
  };

  handleDelete = (element: ArrayInputItem) => {
    const array = this.array();
    const indexToDelete = array.indexOf(element);
    const numItemsToDelete = 1;
    array.splice(indexToDelete, numItemsToDelete);
    this.props.onItemsChange(array, this.props.keys.join(''));
  };

  handleSubmit = (object: Object) => {
    this.setState({ showOverlay: false });
    this.props.updateElement(this.props.keys, [...this.array(), object]);
  };
}

export default ArrayInput;
