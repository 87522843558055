export default {
  ArrayType: 'ArrayType',
  BooleanLiteralType: 'BooleanLiteralType',
  BooleanType: 'BooleanType',
  NumberType: 'NumberType',
  ObjectType: 'ObjectType',
  StringLiteralType: 'StringLiteralType',
  StringType: 'StringType',
  TypeAlias: 'TypeAlias',
  TypeTDZ: 'TypeTDZ',
  UnionType: 'UnionType',
};
