// @flow
import React from 'react';
import type { ComponentElementDefinition } from '@an/nova-form-components';
import ConfirmDialogButton from '../../../../ui/ConfirmDialogButton';

type Props = {
  number: number,
  component: ComponentElementDefinition,
  onClickEdit: () => any,
  removeComponent: () => any,
  reorderComponent: (displacement: number) => any,
};

const ComponentRow = ({ number, component, onClickEdit, removeComponent, reorderComponent }: Props) => (
  <tr key={component.key}>
    <td>{number}</td>
    <td>{component.key}</td>
    <td>{component.type}</td>
    <td style={{ width: 2 }}>
      <button style={{ paddingLeft: 16 }} onClick={onClickEdit} type="button">
        EDIT
      </button>
    </td>
    <td style={{ width: 2 }}>
      <ConfirmDialogButton
        buttonText="DELETE"
        onConfirm={removeComponent}
        messageText={`Would you like to DELETE component ${component.key}`}
      />
    </td>
    <td>
      <div style={{ display: 'flex' }}>
        <button style={{ background: '#11ffff22' }} onClick={() => reorderComponent(-1)} type="button">
          ⬆
        </button>
        <button style={{ background: '#ff111122' }} onClick={() => reorderComponent(1)} type="button">
          ⬇
        </button>
      </div>
    </td>
  </tr>
);

export default ComponentRow;
