// @flow
import React, { Component } from 'react';
import { Button, type FormDefinition } from '@an/nova-form-components';

type Props = {
  addForm: (form: FormDefinition) => any,
};

type State = {
  form: FormDefinition,
};

class NewFormForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        formId: '',
        name: '',
        pages: [],
      },
    };
  }

  render() {
    return (
      <div>
        <h3>FormId</h3>
        <input
          value={this.state.form.formId}
          onChange={(e) => this.handleFormIdChange(e.target.value)}
          style={{ width: '100%' }}
          type="text"
        />
        <h3>Name</h3>
        <input
          value={this.state.form.name}
          onChange={(e) => this.handleFormNameChange(e.target.value)}
          style={{ width: '100%' }}
          type="text"
        />
        <Button
          onClick={() => {
            this.props.addForm(this.state.form);
          }}
          label={`Add Form ${this.state.form.formId}`}
          type="button"
          level="primary"
        />
      </div>
    );
  }

  handleFormIdChange = (formId: string) => this.setState((state) => ({ form: { ...state.form, formId } }));

  handleFormNameChange = (name: string) => this.setState((state) => ({ form: { ...state.form, name } }));
}

export default NewFormForm;
