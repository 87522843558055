// @flow
import React, { type Node } from 'react';
import { Portal } from 'react-portal';
import Modal from 'react-modal';

type Props = {
  children: Node,
  onClose: () => any,
  visible: boolean,
  modalStyle?: Object,
};

const Overlay = ({ children, onClose, visible, modalStyle }: Props) =>
  visible ? (
    <Portal>
      <Modal isOpen={visible} onRequestClose={onClose} style={modalStyle}>
        {children}
      </Modal>
    </Portal>
  ) : null;

Overlay.defaultProps = {
  modalStyle: {},
};

export default Overlay;
