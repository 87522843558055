/* eslint-disable import/no-import-module-exports */
// @flow
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
import rootReducer from './rootReducer';

const loggerMiddleware = createLogger({
  predicate: (getState, action) =>
    action.type !== '@@redux-form/REGISTER_FIELD' &&
    action.type !== '@@redux-form/FOCUS' &&
    action.type !== '@@redux-form/CHANGE' &&
    action.type !== '@@redux-form/BLUR' &&
    action.type !== '@@redux-form/UPDATE_SYNC_ERRORS' &&
    action.type !== '@@redux-form/UNREGISTER_FIELD',
});

const middleware = [];
middleware.push(thunkMiddleware);

if (process.env.NODE_ENV === 'development') {
  middleware.push(immutableCheckMiddleWare());
  middleware.push(loggerMiddleware);
}

export default function configureStore(preloadedState?: Object) {
  // $FlowFixMe WEB-352
  const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(...middleware)));

  // $FlowExpectedError
  if (module.hot) {
    // $FlowExpectedError
    module.hot.accept('./rootReducer', () => {
      // eslint-disable-next-line global-require
      const nextReducer = require('./rootReducer');
      // $FlowFixMe WEB-352
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
