// @flow
// eslint-disable-next-line no-underscore-dangle
const env = window._env_ || process.env;
export default {
  adClientId: env.REACT_APP_AD_CLIENT_ID || '2c27baf3-56e5-42d5-b8ec-ec79475ce890',
  // DEV apiBaseUrl: 'https://dev-nova-app-forms-service.azurewebsites.net',
  apiBaseUrl: env.REACT_APP_API_BASE || 'http://localhost:3001',
  clientBaseUrl: env.REACT_APP_CLIENT_BASE_URL || 'http://localhost:4000',
  stripePublishableKey: env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
  googleReCaptchaKey: env.REACT_APP_GOOGLE_RECAPTCHA_KEY || '',
  sampleIdValidateUrl:
    env.REACT_APP_SAMPLE_ID_VALIDATE_URL ||
    'https://prod-22.uksouth.logic.azure.com/workflows/275250cabfe14152a4697d041ba7528e/triggers/manual/paths/invoke/sampleId/{sampleId}?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=lwLpRSXXS8fvdnDoAiJyreq8uCyjPxU_YjaUi2Wl_GA',
  sampleIdPostUrl:
    env.REACT_APP_SAMPLE_ID_POST_URL ||
    'https://prod-31.uksouth.logic.azure.com/workflows/d4acd0c54d4f4398b25b42318a461e30/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=6aA3xOFkup19NYLch4NC769qbNxykS3R5ixo28sTyu0',
  environment: env.REACT_APP_ENVIRONMENT || 'local',
};
