// @flow
import React from 'react';

import type { HeaderDefinition } from '@an/nova-form-components';

type Props = {
  header: HeaderDefinition,
  updateHeader: (HeaderDefinition) => void,
};

const PageHeader = ({ header, updateHeader }: Props) => (
  <div>
    <h3>Page Header</h3>
    <p>
      <b>Key:</b> <i>{header.key}</i>
    </p>
    <p>
      <b>Type:</b> <i>{header.type}</i>
    </p>
    <div>
      <p>
        <b>Text: </b>
      </p>
      <textarea
        style={{ minHeight: 150 }}
        value={header.text}
        onChange={(e) => updateHeader({ ...header, text: e.target.value })}
      />
    </div>
  </div>
);

export default PageHeader;
