// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HomeScreen, FormScreenLoader as AdminFormScreen } from './admin/screens';

const AppRoutes = () => (
  <Switch>
    <Route exact path="/" component={HomeScreen} />
    <Route exact path="/forms/:id" component={AdminFormScreen} />
  </Switch>
);

export default AppRoutes;
