// @flow
import React from 'react';
import _ from 'lodash';

type Props = {
  keys: string[],
  element: string,
  updateElement: (keys: string[], value: string) => any,
  readonly?: boolean,
};

const NumberInput = ({ keys, element, updateElement, readonly }: Props) => {
  const value = _.get(element, keys.join('.'));
  return readonly ? (
    <p>{value}</p>
  ) : (
    <input type="number" value={value} onChange={(e) => updateElement(keys, e.target.value)} />
  );
};

NumberInput.defaultProps = {
  readonly: false,
};

export default NumberInput;
