// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { type Environment, environments } from '../../constants/environments';
import { EnvironmentButton } from './EnvironmentButton';

type Props = {
  environment: Environment,
};

export const EnvironmentIndicator = ({ environment }: Props) => {
  const { background, displayValue, fontColour } = environments[environment];
  return (
    <div
      className="environment-indicator"
      style={{
        backgroundColor: background,
      }}
    >
      <h1 style={{ color: fontColour }} className="environment-indicator__header section section__form group">
        <Link to="/">
          <strong>{displayValue}</strong> NOVA Forms Admin
        </Link>
      </h1>
      <EnvironmentButton environment={environment} />
    </div>
  );
};
