// @flow
import React from 'react';
import { EnvironmentIndicator } from './EnvironmentIndicator';
import { environments, type Environment } from '../../constants/environments';

type Props = {
  children: React$Node,
  environment: Environment,
};

export const PageWrapper = ({ children, environment }: Props) => (
  <>
    <EnvironmentIndicator environment={environments[environment].id} />
    {children}
  </>
);
