// @flow
import { put } from '@an/nova-frontend-rest-client';
import type { FormDefinition } from '@an/nova-form-components';
import type { Dispatch } from '../../../core/types';

import Config from '../../../config';

export const UPDATE_FORM = 'update_form';

export function updateForm(form: FormDefinition) {
  return async (dispatch: Dispatch<*>) =>
    dispatch(put(`${Config.apiBaseUrl}/forms/${form.formId}`, UPDATE_FORM, undefined, form));
}
