// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import * as moment from 'moment';

import { get } from '@an/nova-frontend-rest-client';
import type { ApiError, FormDefinition } from '@an/nova-form-components';
import { PageWrapper } from '../../../ui/components/PageWrapper';

import Config from '../../../config';
import { updateForm } from './FormScreenLoaderActions';
import { FormScreen } from '../FormScreen/FormScreen';
import { getFormsEnvironment } from '../../../helpers/getFormsEnvironment';
import type { Match } from '../index';

type Props = {
  fetchForm: (formId: string) => any,
  updateForm: (form: FormDefinition) => any,
  match: Match,
};

type State = {|
  addFieldParentComponent?: number,
  error?: ApiError,
  form?: FormDefinition,
  loading: boolean,
  updateStatusMessage: ?string,
|};

// $FlowFixMe WEB-352
const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  // TODO: Do this in actions, not mapDispatchToProps
  fetchForm: (formId) => dispatch(get(`${Config.apiBaseUrl}/forms/${formId}`, 'form')),
  updateForm: (form: FormDefinition) => dispatch(updateForm(form)),
});

export class FormScreenLoader extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      loading: true,
      updateStatusMessage: null,
    };
  }

  // Invalid eslint warning
  // https://github.com/yannickcr/eslint-plugin-react/issues/1110
  async componentDidMount() {
    const { fetchForm, match } = this.props;
    const { id: formId } = match.params;
    const response = await fetchForm(formId);
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      error: response.response.ok ? undefined : response.payload,
      form: response.response.ok ? response.payload : undefined,
      loading: false,
    });
  }

  render() {
    const { error, form, loading, updateStatusMessage } = this.state;
    const environment = getFormsEnvironment();

    const loadingSection = () => <div className="loader">&nbsp;</div>;

    const errorSection = (apiError: ApiError) => (
      <div>
        <h2>Error</h2>
        <div>
          Code: {apiError.code} <br />
          Message: {apiError.message}
        </div>
      </div>
    );

    return (
      <PageWrapper environment={environment}>
        <section className="section section__form group">
          <div className="col span_12_of_12">
            <h2>Form: {form ? form.name : ''}</h2>
            {loading && loadingSection()}
            {error && errorSection(error)}
            {form && (
              <FormScreen initialForm={form} updateForm={this.saveChanges} updateStatusMessage={updateStatusMessage} />
            )}
          </div>
        </section>
      </PageWrapper>
    );
  }

  saveChanges = async (updatedForm: FormDefinition) => {
    if (this.state.form) {
      const res = await this.props.updateForm(updatedForm);
      const updateStatusMessage = res.response.ok
        ? // $FlowFixMe WEB-352
          `Saved form successfully at ${moment().format('HH:mm')}`
        : 'Failed to save form. Try again.';
      this.setState({ updateStatusMessage });
    }
  };
}

// $FlowFixMe WEB-352
export default connect(null, mapDispatchToProps)(FormScreenLoader);
