// @flow
import React from 'react';
import _ from 'lodash';
import type { ObjectTypeProperty, TypeAlias } from 'flow-runtime';
import { Button } from '@an/nova-form-components';
import {
  defaultValues,
  optionalObjectPropertyKeys,
  requiredKeys as requiredKeysHelper,
} from '../../../helpers/flowRuntimeHelpers/typePropertyHelper/typePropertyHelper';
import flowTypes from '../../../helpers/flowRuntimeHelpers/flowTypes';
// eslint-disable-next-line import/no-cycle
import Field from './Field';
import { getAllKeys } from '../../../helpers/formDataHelpers';
import { getTypePropertyStringFromFlowType } from '../../../helpers/flowRuntimeHelpers/formTypesLookup';

type Props<T> = {
  type: ObjectTypeProperty | TypeAlias,
  onSubmit: (T) => any,
  initialElementValues?: T,
  // List of top level keys to exclude from the form
  excludedElementKeys?: string[],
  // List of top level keys that should be readonly if set
  readonlyElementKeys?: string[],
  existingKeys: string[],
};

type State<T> = {
  requiredKeys: Array<Array<string>>,
  element: T,
  errorText: ?string,
  collapsedObjectProperties: Array<Array<string>>,
  hasBeenUpdated: boolean,
};

class AddElementForm<T> extends React.Component<Props<T>, State<T>> {
  static defaultProps = {
    initialElementValues: {},
    excludedElementKeys: ['isComponent'],
    // By default, the property 'key' will be readonly for all elements.
    // Can be overridden by passing a value of 'readonlyElementKeys
    readonlyElementKeys: ['key'],
  };

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      requiredKeys: requiredKeysHelper(this.type(props.type).properties),
      element: defaultValues(this.type(props.type).properties, props.initialElementValues),
      collapsedObjectProperties: optionalObjectPropertyKeys(this.type(props.type).properties),
      errorText: null,
      hasBeenUpdated: false,
    };
  }

  render() {
    const { excludedElementKeys, readonlyElementKeys, initialElementValues } = this.props;
    const type = this.type(this.props.type);
    const typeAlias = _.get(this.props.type, 'name');
    const typeName = getTypePropertyStringFromFlowType(type) || typeAlias || type.typeName;

    const properties = type.properties.filter((p) => excludedElementKeys && !excludedElementKeys.includes(p.key));

    return (
      <div>
        <h2>Add {typeName}</h2>
        {properties.map((p) => (
          <Field
            key={p.key}
            typeProperty={p}
            initialElementValues={this.props.initialElementValues}
            element={this.state.element}
            onConfigChange={(newConfig) => {
              this.setState({ element: newConfig });
            }}
            onItemsChange={(newOrder, key) => {
              this.setState((prevState) => ({
                element: {
                  ...prevState.element,
                  [key]: newOrder,
                },
                hasBeenUpdated: true,
              }));
            }}
            collapsedObjectProperties={this.state.collapsedObjectProperties}
            updateCollapsedObjectProperties={(collapsedObjectProperties) =>
              this.setState({ collapsedObjectProperties })
            }
            readonly={readonlyElementKeys && readonlyElementKeys.includes(p.key) && _.get(initialElementValues, p.key)}
            existingKeys={this.props.existingKeys}
          />
        ))}
        <Button
          onClick={this.handleSubmit}
          label={`${this.props.initialElementValues ? 'Save' : 'Add'} ${typeName}`}
          type="button"
          level="primary"
        />
        {this.state.errorText}
      </div>
    );
  }

  handleSubmit = () => {
    const { type, onSubmit, existingKeys, initialElementValues } = this.props;
    const { element, collapsedObjectProperties, hasBeenUpdated, requiredKeys } = this.state;
    const elementWithoutCollapsedProperties = _.omit(
      element,
      collapsedObjectProperties.map((p) => p.join('.'))
    );

    const requiredKeysWithoutCollapsed = requiredKeys.filter(
      (k) =>
        !optionalObjectPropertyKeys(this.type(type).properties).some((optionalKey) =>
          k.join('.').startsWith(optionalKey.join('.'))
        ) || !collapsedObjectProperties.some((cp) => k.join('.').startsWith(cp.join('.')))
    );

    const requiredParamValues = requiredKeysWithoutCollapsed.map((k) =>
      _.get(elementWithoutCollapsedProperties, k.join('.'))
    );

    if (requiredParamValues.some((v) => _.isUndefined(v))) {
      this.setState({
        errorText: 'one or more required properties are not filled in',
      });
    } else if (hasBeenUpdated) {
      onSubmit(elementWithoutCollapsedProperties);
    } else if (
      getAllKeys(elementWithoutCollapsedProperties).some(
        (key) => existingKeys && existingKeys.includes(key) && _.get(initialElementValues, 'key') !== key
      )
    ) {
      this.setState({
        errorText: 'one or more keys in this element already exist in the form - duplicate keys are not allowed.',
      });
    } else {
      onSubmit(elementWithoutCollapsedProperties);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  type = (type: ObjectTypeProperty | TypeAlias) => {
    if (type.typeName === flowTypes.TypeAlias) {
      return type.type;
    }
    if (type.typeName === flowTypes.TypeTDZ) {
      return type.unwrap();
    }
    return type;
  };
}

export default AddElementForm;
