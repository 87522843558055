// @flow
import React, { Component } from 'react';
import Overlay from './components/Overlay';

type Props = {
  buttonText: string,
  messageText: string,
  onConfirm: () => any,
  buttonStyle?: Object,
  confirmText?: string,
  cancelText?: string,
};

type State = {
  showDialog: boolean,
};

class ConfirmDialogButton extends Component<Props, State> {
  static defaultProps = {
    confirmText: 'Yes',
    cancelText: 'No',
    buttonStyle: {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }

  render() {
    return (
      <div>
        <Overlay
          visible={this.state.showDialog}
          onClose={this.cancel}
          modalStyle={{
            content: {
              width: 500,
              height: 500,
              top: '20%',
              left: '35%',
            },
          }}
        >
          <p>
            <b>{this.props.messageText}</b>
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <button onClick={this.confirm} type="button">
              {this.props.confirmText}
            </button>
            <button onClick={this.cancel} type="button">
              {this.props.cancelText}
            </button>
          </div>
        </Overlay>
        <button style={this.props.buttonStyle} onClick={() => this.setState({ showDialog: true })} type="button">
          {this.props.buttonText}
        </button>
      </div>
    );
  }

  cancel = () => {
    this.setState({ showDialog: false });
  };

  confirm = () => {
    this.setState({ showDialog: false });
    this.props.onConfirm();
  };
}

export default ConfirmDialogButton;
