// @flow
import React from 'react';
import arrayMove from 'array-move';
import type { FieldsetDefinition, FieldElementDefinition, ComponentElementDefinition } from '@an/nova-form-components';

import ComponentRow from './ComponentRow';
import AddFieldOverlay from '../../formFields/AddFieldOverlay/AddFieldOverlay';
import FieldRow from './FieldRow';

type Props = {
  number: number,
  fieldSet: FieldsetDefinition,
  onClickEdit: () => any,
  addOrUpdateComponent: (ComponentElementDefinition) => any,
  removeComponent: () => any,
  reorderComponent: (displacement: number) => any,
  existingKeys: string[],
};

type State = {
  showOverlay: boolean,
  editingField?: FieldElementDefinition,
};

class FieldSetRows extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showOverlay: false,
      editingField: undefined,
    };
  }

  render() {
    const { onClickEdit, number, fieldSet } = this.props;
    const addFieldButtonRow = (
      <tr key={`${fieldSet.key}-addfield`}>
        <td />
        <td>
          <button onClick={() => this.setState({ showOverlay: true })} type="button">
            Add field
          </button>
        </td>
      </tr>
    );

    return (
      <tbody>
        <AddFieldOverlay
          onClose={this.hideAddFieldOverlay}
          visible={this.state.showOverlay}
          addOrUpdateField={this.addOrUpdateField}
          editingField={this.state.editingField}
          existingKeys={this.props.existingKeys}
        />
        <ComponentRow
          onClickEdit={onClickEdit}
          number={number}
          component={fieldSet}
          type={`Fieldset (${fieldSet.fields.length} fields)`}
          removeComponent={this.props.removeComponent}
          reorderComponent={this.props.reorderComponent}
        />
        {fieldSet.fields.map((field, i) => (
          <FieldRow
            key={field.key}
            onClickEdit={this.handleEditFieldClick(field)}
            removeField={() => this.removeField(field)}
            reorderField={(displacement: number) => this.reorderField(field, displacement)}
            number={`${number}.${i + 1}`}
            field={field}
          />
        ))}
        {addFieldButtonRow}
      </tbody>
    );
  }

  addOrUpdateField = (field: FieldElementDefinition) => {
    const { fieldSet } = this.props;
    const updatedFieldSet: FieldsetDefinition = { ...fieldSet };
    const fieldIndex = fieldSet.fields.findIndex((f) => f.key === field.key);
    if (fieldIndex === -1) {
      updatedFieldSet.fields = [...fieldSet.fields, field];
    } else {
      updatedFieldSet.fields.splice(fieldIndex, 1, field);
    }

    this.setState({ showOverlay: false });
    this.props.addOrUpdateComponent(updatedFieldSet);
  };

  removeField = (field: FieldElementDefinition) => {
    const { fieldSet } = this.props;
    const updatedFieldSet: FieldsetDefinition = { ...fieldSet };
    const fieldIndex = fieldSet.fields.findIndex((f) => f.key === field.key);
    updatedFieldSet.fields.splice(fieldIndex, 1);

    this.setState({ showOverlay: false });
    this.props.addOrUpdateComponent(updatedFieldSet);
  };

  reorderField = (field: FieldElementDefinition, displacement: number) => {
    const { fieldSet } = this.props;
    const fieldIndex = fieldSet.fields.findIndex((f) => f.key === field.key);
    if (fieldIndex + displacement < 0) {
      return;
    }
    this.props.addOrUpdateComponent({
      ...fieldSet,
      fields: arrayMove(fieldSet.fields, fieldIndex, fieldIndex + displacement),
    });
  };

  hideAddFieldOverlay = () => {
    this.setState({ showOverlay: false, editingField: undefined });
  };

  handleEditFieldClick = (field: FieldElementDefinition) => () =>
    this.setState({ editingField: field, showOverlay: true });
}

export default FieldSetRows;
