// @flow
import * as React from 'react';

type Props = {
  label: string,
  children: React.Node,
  style: Object,
};

const Labelled = ({ label, children, style }: Props) => (
  <div style={style}>
    <h3>{label}</h3>
    {children}
  </div>
);

export default Labelled;
