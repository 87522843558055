// @flow
import React from 'react';
import type { FieldElementDefinition } from '@an/nova-form-components';
import ConfirmDialogButton from '../../../../ui/ConfirmDialogButton';

type Props = {
  number: string,
  field: FieldElementDefinition,
  onClickEdit: () => any,
  removeField: () => any,
  reorderField: (displacement: number) => any,
};

const FieldRow = ({ number, field, onClickEdit, removeField, reorderField }: Props) => (
  <tr key={field.key} style={{ backgroundColor: '#dddddd88' }}>
    <td>{number}</td>
    <td>{field.key}</td>
    <td>{field.type}</td>
    <td style={{ width: 2 }}>
      <button style={{ paddingLeft: 16 }} onClick={onClickEdit} type="button">
        EDIT
      </button>
    </td>
    <td style={{ width: 2 }}>
      <ConfirmDialogButton
        buttonText="DELETE"
        messageText={`Would you like to DELETE field ${field.key}`}
        onConfirm={removeField}
      />
    </td>
    <td>
      <div style={{ display: 'flex' }}>
        <button style={{ background: '#11ffff22' }} onClick={() => reorderField(-1)} type="button">
          ⬆
        </button>
        <button style={{ background: '#ff111122' }} onClick={() => reorderField(1)} type="button">
          ⬇
        </button>
      </div>
    </td>
  </tr>
);

export default FieldRow;
