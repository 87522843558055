// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import type { FormDefinition, PageDefinition } from '@an/nova-form-components';
import {
  Button,
  // $FlowExpectedError - this import is possible thanks to flow-runtime
  FormDefinition as FormDefinitionObject,
} from '@an/nova-form-components';
import Config from '../../../config';
import PageSelector from '../../components/formPages/PageSelector/PageSelector';
import PageForm from '../../components/formPages/PageForm';
import AddElementForm from '../../components/formInputs/AddElementForm';
import Overlay from '../../../ui/components/Overlay';
import { getAllKeys } from '../../../helpers/formDataHelpers';
import { getFormsEnvironment } from '../../../helpers/getFormsEnvironment';
import { environments, getIsLocalEnvironment } from '../../../constants/environments';

type Props = {
  initialForm: FormDefinition,
  updateForm: (form: FormDefinition) => any,
  updateStatusMessage: ?string,
};

type State = {|
  addFieldParentComponent?: number,
  selectedPage: number,
  form: FormDefinition,
  showOverlay: boolean,
|};

export class FormScreen extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      selectedPage: 1,
      form: props.initialForm,
      showOverlay: false,
    };
  }

  render() {
    const { form, selectedPage, showOverlay } = this.state;
    const { updateForm, updateStatusMessage } = this.props;
    const currentPage = form && form.pages[selectedPage - 1];
    const existingFormKeys = getAllKeys(form);
    const environment = getFormsEnvironment();
    const isLocalEnvironment = getIsLocalEnvironment(environment);

    return (
      <div>
        <Overlay onClose={() => this.setState({ showOverlay: false })} visible={showOverlay}>
          <AddElementForm
            type={FormDefinitionObject}
            onSubmit={(updatedForm: FormDefinition) =>
              this.setState({
                form: updatedForm,
                showOverlay: false,
              })
            }
            initialElementValues={form}
            excludedElementKeys={['pages']}
            existingKeys={[]}
            readonlyElementKeys={['formId']}
          />
        </Overlay>
        <PageSelector
          pages={form.pages}
          selectedPage={selectedPage}
          onPageSelect={this.handleSelectPage}
          addPage={this.addPage}
          existingKeys={existingFormKeys}
        />
        {currentPage && (
          <PageForm
            page={currentPage}
            updatePage={this.updatePage}
            removePage={() => this.removePage(currentPage)}
            reorderPage={(displacement) => this.reorderPage(currentPage, displacement)}
            existingKeys={existingFormKeys}
          />
        )}
        <Button
          onClick={() => this.setState({ showOverlay: true })}
          label="Edit Form Details"
          type="button"
          level="primary"
        />
        <Button onClick={() => updateForm(form)} label="Save Changes" type="button" level="primary" />
        {updateStatusMessage &&
          (isLocalEnvironment ? (
            <Link to={`/preview/${form.formId}`}>Preview</Link>
          ) : (
            <a
              href={`${environments[Config.environment].frontendUrl}/${form.formId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          ))}
        <div>{updateStatusMessage}</div>
      </div>
    );
  }

  addPage = (page: PageDefinition) => {
    this.setState((state) => ({
      form: {
        ...state.form,
        pages: [...state.form.pages, page],
      },
      selectedPage: page.page,
    }));
  };

  updatePage = (page: PageDefinition) => {
    this.setState((state) => ({
      form: {
        ...state.form,
        pages: [
          ...state.form.pages.slice(0, state.selectedPage - 1),
          page,
          ...state.form.pages.slice(state.selectedPage),
        ],
      },
      selectedPage: page.page,
    }));
  };

  reorderPage = (page: PageDefinition, displacement: number) => {
    const { form, selectedPage } = this.state;
    const selectedPageIndex = selectedPage - 1;
    const newPageIndex = selectedPageIndex + displacement;
    if (newPageIndex < 0 || newPageIndex >= form.pages.length) {
      return;
    }
    const newPages = arrayMove(form.pages, selectedPageIndex, newPageIndex).map((p, i) => ({ ...p, page: i + 1 }));
    this.setState((state) => ({
      form: {
        ...state.form,
        pages: newPages,
      },
      selectedPage: selectedPage + displacement,
    }));
  };

  removePage = (page: PageDefinition) => {
    const { form } = this.state;
    const updatedForm: FormDefinition = { ...form };
    if (updatedForm) {
      const pageIndex = updatedForm.pages.findIndex((p) => p.key === page.key);
      updatedForm.pages = updatedForm.pages.reduce((pages, p, index) => {
        if (index !== pageIndex) {
          const newIndex = index < pageIndex ? index + 1 : index;
          // $FlowExpectedError - should be resolved by https://github.com/facebook/flow/issues/2405
          pages.push({ ...p, page: newIndex });
        }
        return pages;
      }, []);
      this.setState({
        form: updatedForm,
        selectedPage: 1,
      });
    }
  };

  handleSelectPage = (page: PageDefinition) => this.setState({ selectedPage: page.page });
}

export default FormScreen;
