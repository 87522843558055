// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthenticationScreens, LoggedInRoute } from '@an/nova-frontend-auth';
import { FormScreen } from '@an/nova-form-components';
import AppRoutes from './AppRoutes';

import './assets/styles/App.scss'; // App specific styles

export default () => (
  <main role="main">
    <Switch>
      <Route path="/preview/:id" component={FormScreen} />
      <Route path="/auth" component={AuthenticationScreens} />
      <LoggedInRoute component={AppRoutes} />
    </Switch>
  </main>
);
