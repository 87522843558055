// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureFormComponents } from '@an/nova-form-components';
import { AdalAuthModule, initialise } from '@an/nova-frontend-auth';
import { setAuthenticationModule } from '@an/nova-frontend-rest-client';
import Config from './config';
import configureStore from './core/redux/configureStore';
import App from './App';
import { unregister } from './registerServiceWorker';

const store = configureStore();
initialise(Config.adClientId, 'ukmarrow.org', Config.clientBaseUrl, {
  'http://localhost:3001': 'https://ukmarrow.org/dev-nova-forms-backend',
  'https://dev-nova-app-forms-service.azurewebsites.net': 'https://ukmarrow.org/dev-nova-forms-backend',
  'https://uat-nova-app-forms-service.azurewebsites.net': 'https://ukmarrow.org/uat-nova-forms-backend',
  'https://live-nova-app-forms-service.azurewebsites.net': 'https://ukmarrow.org/live-nova-forms-backend',
  'http://localhost:4000': 'https://ukmarrow.org/dev-nova-forms-backend',
});
setAuthenticationModule(AdalAuthModule);
configureFormComponents(
  `${Config.apiBaseUrl}/dummy`,
  // un-comment for local backend validation and payment testing
  // Config.apiBaseUrl,
  Config.stripePublishableKey,
  Config.googleReCaptchaKey,
  Config.sampleIdValidateUrl,
  Config.sampleIdPostUrl,
  AdalAuthModule
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  // $FlowExpectedError
  document.getElementById('root')
);

Modal.setAppElement('body');
unregister();
