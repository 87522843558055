// @flow
import React from 'react';
import _ from 'lodash';

type Props = {
  keys: string[],
  element: boolean,
  updateElement: (keys: string[], value: string) => any,
};

const BooleanInput = ({ keys, element, updateElement }: Props) => {
  const isChecked = _.get(element, keys.join('.'));
  return (
    <input
      type="checkbox"
      value={isChecked}
      checked={isChecked}
      style={{ width: 30, height: 30 }}
      onChange={() => updateElement(keys, !isChecked)}
    />
  );
};

export default BooleanInput;
