// @flow
import React from 'react';
import _ from 'lodash';
import type { ObjectTypeProperty } from 'flow-runtime';
import type { ArrayInputItem } from './types';

import flowTypes from '../../../helpers/flowRuntimeHelpers/flowTypes';
import StringInput from './inputs/StringInput';
import StringLiteralInput from './inputs/StringLiteralInput';
import BooleanInput from './inputs/BooleanInput';
import UnionInput from './inputs/UnionInput';
import NumberInput from './inputs/NumberInput';
// eslint-disable-next-line import/no-cycle
import ArrayInput from './inputs/ArrayInput';
import { getTypeValue } from '../../../helpers/flowRuntimeHelpers/typePropertyHelper/typePropertyHelper';
// eslint-disable-next-line import/no-cycle
import ObjectInput from './inputs/ObjectInput';
import Labelled from '../../../ui/Labelled';

type Props = {
  typeProperty: ObjectTypeProperty,
  initialElementValues?: Object,
  parents?: string[],
  element: Object,
  onConfigChange: (newObject: Object) => any,
  onItemsChange: (newOrder: ArrayInputItem[], key: string) => any,
  collapsedObjectProperties: Array<Array<string>>,
  updateCollapsedObjectProperties: (Array<Array<string>>) => any,
  readonly?: boolean,
  existingKeys: string[],
};

const inputSectionStyle = { paddingBottom: 16 };

const Field = ({
  typeProperty,
  initialElementValues,
  parents,
  element,
  onConfigChange,
  onItemsChange,
  collapsedObjectProperties,
  updateCollapsedObjectProperties,
  readonly,
  existingKeys,
}: Props) => {
  const typeValue = getTypeValue(typeProperty);
  const style = {
    ...inputSectionStyle,
    marginLeft: 24 * (parents ? parents.length : 1),
  };
  const keys = parents ? parents.concat(typeProperty.key) : [];
  const label = `${keys.join('.')}${typeProperty.optional ? '' : '*'}`;

  const updateElement = (objKeys: string[], value: string) => {
    const object = objKeys.reduceRight((obj, key) => (_.isEmpty(obj) ? { [key]: value } : { [key]: obj }), {});
    onConfigChange(_.merge({}, element, object));
  };

  switch (typeValue.typeName) {
    case flowTypes.StringType:
      return (
        <Labelled style={style} label={label}>
          <StringInput keys={keys} element={element} updateElement={updateElement} readonly={readonly} />
        </Labelled>
      );
    case flowTypes.StringLiteralType:
      return (
        <Labelled style={style} label={label}>
          <StringLiteralInput value={typeValue.value.toString()} />
        </Labelled>
      );
    case flowTypes.BooleanType:
      return (
        <Labelled style={style} label={label}>
          <BooleanInput keys={keys} element={element} updateElement={updateElement} />
        </Labelled>
      );
    // This assumes that boolean literals should not be returned to the admin,
    // eg isComponent or isField
    case flowTypes.BooleanLiteralType:
      return null;
    // We currently assume only union types of string literals will be used,
    // which are mapped to a select field
    case flowTypes.UnionType:
      return (
        <Labelled style={style} label={label}>
          <UnionInput
            keys={keys}
            element={element}
            updateElement={updateElement}
            options={typeValue.types.filter((t) => t.typeName === flowTypes.StringLiteralType).map((t) => t.value)}
          />
        </Labelled>
      );
    case flowTypes.NumberType:
      return (
        <Labelled style={style} label={label}>
          <NumberInput keys={keys} element={element} updateElement={updateElement} readonly={readonly} />
        </Labelled>
      );
    case flowTypes.ArrayType:
      return (
        <Labelled style={style} label={label}>
          <ArrayInput
            keys={keys}
            initialElementValues={initialElementValues}
            element={element}
            updateElement={updateElement}
            typeProperty={typeValue.elementType}
            existingKeys={existingKeys}
            onItemsChange={onItemsChange}
          />
        </Labelled>
      );
    case flowTypes.ObjectType:
      return (
        <ObjectInput
          style={style}
          typeProperty={typeProperty}
          initialElementValues={initialElementValues}
          keys={keys}
          element={element}
          onConfigChange={onConfigChange}
          onItemsChange={onItemsChange}
          collapsedObjectProperties={collapsedObjectProperties}
          updateCollapsedObjectProperties={updateCollapsedObjectProperties}
          existingKeys={existingKeys}
        />
      );
    default:
      return (
        <div>
          Unrecognised element property: <b>{label}</b>. Of type: <i>{typeValue.toString()}</i>
        </div>
      );
  }
};

Field.defaultProps = {
  initialElementValues: {},
  parents: [],
  readonly: false,
};

export default Field;
