// @flow
import React from 'react';
import _ from 'lodash';

type Props = {
  keys: string[],
  element: Object,
  updateElement: (keys: string[], value: string) => any,
  options: string[],
};

const UnionInput = ({ keys, element, updateElement, options }: Props) => (
  <select value={_.get(element, keys.join('.'))} onChange={(e) => updateElement(keys, e.target.value)}>
    {[
      <option key="unselected" value="">
        Please select...
      </option>,
      ...options.map((o) => (
        <option key={o} value={o}>
          {o}
        </option>
      )),
    ]}
  </select>
);

export default UnionInput;
