// @flow
import _ from 'lodash';

import type { FormDefinition } from '@an/nova-form-components';

const getAllValuesForKey = (object: Object, keyName: string): string[] => {
  const key = object[keyName];
  return _.compact(
    Object.values(object).reduce(
      (result, v) => {
        if (typeof v === 'object') {
          return result.concat(getAllValuesForKey(v, keyName));
        }
        return result;
      },
      [key]
    )
  );
};

// eslint-disable-next-line import/prefer-default-export
export const getAllKeys = (form: FormDefinition): string[] => getAllValuesForKey(form, 'key');
