// @flow
const DEV: 'dev' = 'dev';
const UAT: 'uat' = 'uat';
const LIVE: 'live' = 'live';
const LOCAL: 'local' = 'local';

export const environments = {
  local: {
    id: LOCAL,
    background: '#007bff',
    displayValue: LOCAL.toUpperCase(),
    fontColour: '#fff',
    url: 'http://localhost:4000',
  },
  dev: {
    id: DEV,
    background: '#00703c',
    displayValue: DEV.toUpperCase(),
    fontColour: '#fff',
    url: 'https://dev-forms-admin.anthonynolan.org',
    frontendUrl: 'https://webformsdev.anthonynolan.org',
  },
  uat: {
    id: UAT,
    background: '#ffdd00',
    displayValue: UAT.toUpperCase(),
    fontColour: '#000',
    url: 'https://uat-forms-admin.anthonynolan.org',
    frontendUrl: 'https://webformsstage.anthonynolan.org',
  },
  live: {
    id: LIVE,
    background: '#d4351c',
    displayValue: LIVE.toUpperCase(),
    fontColour: '#fff',
    url: 'https://forms-admin.anthonynolan.org',
    frontendUrl: 'https://webforms.anthonynolan.org',
  },
};

export type Environment =
  | typeof environments.local.id
  | typeof environments.dev.id
  | typeof environments.uat.id
  | typeof environments.live.id;

export const getEnvironmentSelectOptions = (): {
  key: Environment,
  value: string,
}[] =>
  Object.keys(environments).map((env) => ({
    key: environments[env].id,
    value: environments[env].displayValue,
  }));

export const getFilteredEnvironmentLinks = (
  environment: string
): ?({
  url: string,
  displayValue: string,
}[]) => {
  const alternativeEnvironments = Object.keys(environments).filter(
    (env) => env !== environment && env !== environments.local.id
  );
  return alternativeEnvironments.map((env) => ({
    displayValue: environments[env].displayValue,
    url: environments[env].url,
  }));
};

export const getIsLocalEnvironment = (environment: Environment): boolean => environment === environments.local.id;

export const getIsDevelopmentEnvironment = (environment: Environment): boolean =>
  environment === environments.local.id || environment === environments.dev.id;
