// @flow
import type { TypeAlias, Type } from 'flow-runtime';

import * as Types from '@an/nova-form-components';

export const componentTypes: () => TypeAlias[] = () =>
  Object.values(Types).filter(
    (t) =>
      t &&
      t.type &&
      t.type.properties &&
      Array.isArray(t.type.properties) &&
      t.type.properties.find(
        // $FlowExpectedError - flow doesn't like Object.values
        (p) => p.key === 'isComponent' && p.value && p.value.value
      ) &&
      t.name &&
      t.name !== 'ComponentElementDefinition' &&
      t.name !== 'ListComponentElementDefinition' &&
      t.name !== 'OptionsComponentElementDefinition'
  );

export const fieldTypes: () => TypeAlias[] = () =>
  Object.values(Types).filter(
    (t) =>
      t &&
      t.type &&
      t.type.properties &&
      // $FlowExpectedError - flow doesn't like Object.values
      t.type.properties.find((p) => p.key === 'isField' && p.value.value) &&
      t.name &&
      t.name !== 'FieldElementDefinition' &&
      t.name !== 'OptionsElementDefinition'
  );

export const getTypePropertyStringFromFlowType = (t: TypeAlias | Type): string => {
  const properties = t.type ? t.type.properties : t.properties;
  const typeProperty = properties && properties.find((p) => p.key === 'type');
  return typeProperty && typeProperty.value.unwrap().value;
};

export const componentTypeStrings: () => string[] = () => componentTypes().map(getTypePropertyStringFromFlowType);

export const fieldTypeStrings: () => string[] = () => fieldTypes().map(getTypePropertyStringFromFlowType);

export const componentType = (componentTypeString: string): Type => {
  const cType = componentTypes().find((type) => getTypePropertyStringFromFlowType(type) === componentTypeString);
  return cType && cType.type;
};

export const fieldType = (fieldTypeString: string) => {
  const fType = fieldTypes().find((type) => getTypePropertyStringFromFlowType(type) === fieldTypeString);
  return fType && fType.type;
};
