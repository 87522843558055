// @flow
import React from 'react';
import _ from 'lodash';

type Props = {
  keys: string[],
  element: any,
  updateElement: (keys: string[], value: string) => any,
  readonly?: boolean,
};

const StringInput = ({ keys, element, updateElement, readonly }: Props) => {
  const value = _.get(element, keys.join('.'));
  const shouldShowTextArea = keys.includes('text') || keys.includes('description') || keys.includes('html');

  const handleChange = (e) => {
    updateElement(keys, e.target.value);
  };

  if (readonly) {
    return <p>{value}</p>;
  }

  return shouldShowTextArea ? (
    <textarea value={value} rows="3" cols="50" onChange={handleChange} />
  ) : (
    <input type="text" value={value} onChange={handleChange} />
  );
};

StringInput.defaultProps = {
  readonly: false,
};

export default StringInput;
