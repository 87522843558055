// @flow
import React from 'react';
import { ObjectTypeProperty } from 'flow-runtime';
import Collapsible from 'react-collapsible';
import _ from 'lodash';
import { getTypeValue } from '../../../../helpers/flowRuntimeHelpers/typePropertyHelper/typePropertyHelper';
// eslint-disable-next-line import/no-cycle
import Field from '../Field';
import type { ArrayInputItem } from '../types';

type Props = {
  typeProperty: ObjectTypeProperty,
  initialElementValues: Object,
  keys: string[],
  element: Object,
  onConfigChange: (newObject: Object) => any,
  onItemsChange: (newOrder: ArrayInputItem[], key: string) => any,
  style: Object,
  collapsedObjectProperties: Array<Array<string>>,
  updateCollapsedObjectProperties: (Array<Array<string>>) => any,
  existingKeys: string[],
};

class ObjectInput extends React.Component<Props> {
  render() {
    const {
      keys,
      typeProperty,
      element,
      onConfigChange,
      onItemsChange,
      style,
      initialElementValues,
      collapsedObjectProperties,
      updateCollapsedObjectProperties,
      existingKeys,
    } = this.props;
    const typeValue = getTypeValue(typeProperty);
    const fieldLabel = `${keys.join('.')}${typeProperty.optional ? '' : '*'}`;
    return (
      <div style={style}>
        <Collapsible
          trigger={<h3 style={{ cursor: 'pointer' }}>{fieldLabel} ▼</h3>}
          triggerWhenOpen={<h3 style={{ cursor: 'pointer' }}>{fieldLabel} ▲</h3>}
          open={!this.isCollapsed()}
          handleTriggerClick={this.handleCollapsibleClick}
        >
          {typeValue.properties.map((p) => (
            <Field
              key={p.key}
              typeProperty={p}
              parents={keys}
              element={element}
              onConfigChange={onConfigChange}
              onItemsChange={onItemsChange}
              initialElementValues={initialElementValues}
              collapsedObjectProperties={collapsedObjectProperties}
              updateCollapsedObjectProperties={updateCollapsedObjectProperties}
              existingKeys={existingKeys}
            />
          ))}
        </Collapsible>
      </div>
    );
  }

  handleCollapsibleClick = () => {
    const { collapsedObjectProperties, keys, updateCollapsedObjectProperties } = this.props;
    const newProperties = this.isCollapsed()
      ? collapsedObjectProperties.filter((p) => !_.isEqual(p, keys))
      : collapsedObjectProperties.concat([keys]);
    updateCollapsedObjectProperties(newProperties);
  };

  isCollapsed = () => this.props.collapsedObjectProperties.some((p) => _.isEqual(p, this.props.keys));
}

export default ObjectInput;
