// @flow
import React from 'react';
import _ from 'lodash';
import type { ComponentElementDefinition, ListComponentElementDefinition } from '@an/nova-form-components';
import { getComponentConfig } from '@an/nova-form-components';

import Overlay from '../../../../ui/components/Overlay';
import AddElementForm from '../../formInputs/AddElementForm';
import {
  componentTypeStrings,
  componentType as getComponentType,
} from '../../../../helpers/flowRuntimeHelpers/formTypesLookup';
import componentConfig from '../ComponentTable/componentConfig';

type Props = {
  addOrUpdateComponent: ($Shape<ListComponentElementDefinition>) => any,
  onClose: () => any,
  visible: boolean,
  editingComponent?: $Shape<ListComponentElementDefinition>,
  existingComponents: $Shape<ListComponentElementDefinition>[],
  existingKeys: string[],
};

type State = {
  componentType: ?string,
};

const defaultState = {
  componentType: null,
};

const getAllUniqueItems = (editingComponent: $Shape<ListComponentElementDefinition>) => {
  if (editingComponent && editingComponent.items) {
    const defaultComponentConfig = getComponentConfig(editingComponent);
    const defaultItems = _.get(defaultComponentConfig, 'defaultItems', []);
    const componentItems = _.get(editingComponent, 'items', []);
    const totalItems = [...componentItems, ...defaultItems];
    const uniqueItems = _.uniqBy(totalItems, 'key');
    return {
      items: uniqueItems,
    };
  }
  return {};
};

export default class AddComponentOverlay extends React.Component<Props, State> {
  static defaultProps = {
    editingComponent: undefined,
  };

  constructor(props: Props, context: any) {
    super(props, context);
    this.state = defaultState;
  }

  render() {
    const { editingComponent, existingComponents, existingKeys, visible } = this.props;

    const combinedEditingComponent = {
      ...editingComponent,
      ...getAllUniqueItems(editingComponent || {}),
    };

    const componentType = this.componentType();
    let body;
    if (componentType) {
      const excludedPropertyValues = _.get(componentConfig, `${componentType}.excludedPropertyValues`);
      body = (
        <AddElementForm
          type={getComponentType(componentType)}
          // $FlowFixMe - type and key are missing in object literal
          onSubmit={this.addOrUpdateComponent}
          initialElementValues={combinedEditingComponent || excludedPropertyValues}
          excludedElementKeys={excludedPropertyValues && Object.keys(excludedPropertyValues)}
          existingComponents={existingComponents}
          existingKeys={existingKeys}
        />
      );
    } else {
      body = (
        <div>
          {componentTypeStrings().map((ft) => (
            <button
              key={ft}
              onClick={() => this.selectComponentType(ft)}
              style={{ display: 'block', margin: '0.5rem 0' }}
              type="button"
            >
              {`Add ${ft}`}
            </button>
          ))}
        </div>
      );
    }

    return (
      <Overlay onClose={this.onClose} visible={visible}>
        {body}
      </Overlay>
    );
  }

  componentType = () => {
    const editingComponentType = _.get(this.props.editingComponent, 'type');
    return editingComponentType || this.state.componentType;
  };

  onClose = () => {
    this.setState(defaultState);
    this.props.onClose();
  };

  selectComponentType = (type: ?string) => {
    this.setState({ componentType: type });
  };

  addOrUpdateComponent = (component: ComponentElementDefinition) => {
    this.setState(defaultState);
    this.props.addOrUpdateComponent(component);
  };
}
