// @flow
import React from 'react';

import { PageDefinition } from '@an/nova-form-components';

type Props = {
  page: PageDefinition,
  active: boolean,
  onPageSelect: (PageDefinition) => any,
};

const PageButton = ({ page, active, onPageSelect }: Props) => (
  <button
    disabled={active}
    key={page.page}
    onClick={() => onPageSelect(page)}
    style={{
      display: 'inline-block',
      fontWeight: active ? 'bold' : null,
    }}
    type="button"
  >
    {`Page: ${page.page}`}
  </button>
);

export default PageButton;
