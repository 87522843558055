// @flow
import React from 'react';
import arrayMove from 'array-move';

import type { PageDefinition, ComponentElementDefinition } from '@an/nova-form-components';

import ComponentTable from '../formComponents/ComponentTable/ComponentTable';
import PageHeader from './PageHeader';
import ConfirmDialogButton from '../../../ui/ConfirmDialogButton';

type Props = {
  page: PageDefinition,
  updatePage: (PageDefinition) => void,
  removePage: () => any,
  reorderPage: (displacement: number) => any,
  existingKeys: string[],
};

type State = {};

class PageForm extends React.Component<Props, State> {
  render() {
    const { page } = this.props;
    const divider = <div style={{ borderBottom: 'solid #aaaaaa' }} />;
    // The specified keys of a page will be rendered directly, not dynamically.
    // The rest will be displayed as a list of 'other' properties.
    // If we require these to be editable, we can use an 'AddElementForm' in an overlay
    const unrenderedKeys = Object.keys(page).filter(
      (k) => !['key', 'page', 'header', 'type', 'components'].includes(k)
    );
    return (
      <div
        style={{
          borderColor: '#aaaaaaaa',
          borderStyle: 'solid',
          padding: 8,
        }}
      >
        <p>
          <b>Page Key:</b> <i>{page.key}</i>
        </p>
        <p>
          <b>Page Type:</b> <i>{page.type}</i>
        </p>
        <ConfirmDialogButton
          buttonText="Delete Page"
          messageText={`Would you like to DELETE page ${page.page}`}
          onConfirm={this.props.removePage}
        />
        <div style={{ display: 'flex' }}>
          <button style={{ background: '#11ffff22' }} onClick={() => this.props.reorderPage(-1)} type="button">
            ⬆
          </button>
          <button style={{ background: '#ff111122' }} onClick={() => this.props.reorderPage(1)} type="button">
            ⬇
          </button>
        </div>
        {divider}
        {page.header && (
          <PageHeader header={page.header} updateHeader={(header) => this.updatePage({ ...page, header })} />
        )}
        {page.type === 'page' && page.components && (
          <div>
            {divider}
            <ComponentTable
              components={page.components}
              addOrUpdateComponent={this.addOrUpdateComponent}
              removeComponent={this.removeComponent}
              reorderComponent={this.reorderComponent}
              existingKeys={this.props.existingKeys}
            />
          </div>
        )}
        {unrenderedKeys.length > 0 && (
          <div>
            {divider}
            <h3>Other page properties</h3>
            {unrenderedKeys}
          </div>
        )}
      </div>
    );
  }

  updatePage = (page: $Shape<PageDefinition>) => this.props.updatePage(page);

  addOrUpdateComponent = (component: ComponentElementDefinition) => {
    const { page } = this.props;
    const updatedPage = { ...page };
    if (updatedPage.components) {
      const componentIndex = updatedPage.components.findIndex((c) => c.key === component.key);
      if (componentIndex === -1) {
        updatedPage.components = [...updatedPage.components, component];
      } else {
        // If the key already exists, treat as an update and use the new version
        updatedPage.components.splice(componentIndex, 1, component);
      }
      this.updatePage(updatedPage);
    }
    return page;
  };

  reorderComponent = (component: ComponentElementDefinition, displacement: number) => {
    const { page } = this.props;
    const componentIndex = page.components && page.components.findIndex((c) => c.key === component.key);
    if (componentIndex + displacement < 0) {
      return;
    }
    this.updatePage({
      ...page,
      components: arrayMove(page.components, componentIndex, componentIndex + displacement),
    });
  };

  removeComponent = (component: ComponentElementDefinition) => {
    const { page } = this.props;
    const updatedPage: $Shape<PageDefinition> = { ...page };
    if (updatedPage.components) {
      const componentIndex = updatedPage.components.findIndex((c) => c.key === component.key);
      updatedPage.components.splice(componentIndex, 1);
      this.updatePage(updatedPage);
    }
    return page;
  };
}

export default PageForm;
