// @flow
import {
  get,
  post,
  type ErrorAction as ApiErrorAction,
  type RequestAction as ApiRequestAction,
  type SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import type { FormDefinition } from '@an/nova-form-components';
import type { ReduxState } from '../../../core/redux/rootReducer';

import Config from '../../../config';

const CREATE_FORM: 'create-form' = 'create-form';
const CREATE_FORM_REQUEST: 'create-form/request' = 'create-form/request';
const CREATE_FORM_SUCCESS: 'create-form/success' = 'create-form/success';
const CREATE_FORM_ERROR: 'create-form/error' = 'create-form/error';

const FETCH_FORMS: 'fetch-forms' = 'fetch-forms';
const FETCH_FORMS_REQUEST: 'fetch-forms/request' = 'fetch-forms/request';
const FETCH_FORMS_SUCCESS: 'fetch-forms/success' = 'fetch-forms/success';
const FETCH_FORMS_ERROR: 'fetch-forms/error' = 'fetch-forms/error';

export const Actions = {
  CREATE_FORM,
  CREATE_FORM_REQUEST,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_ERROR,
  FETCH_FORMS,
  FETCH_FORMS_REQUEST,
  FETCH_FORMS_SUCCESS,
  FETCH_FORMS_ERROR,
};

type CreateFormAction = ApiRequestAction<typeof CREATE_FORM_REQUEST, {}>;

type CreateFormSuccessAction = ApiSuccessAction<typeof CREATE_FORM_SUCCESS, {}, FormDefinition[]>;

type CreateFormErrorAction = ApiErrorAction<typeof CREATE_FORM_ERROR, {}>;

type FetchFormsAction = ApiRequestAction<typeof FETCH_FORMS_REQUEST, {}>;

type FetchFormsSuccessAction = ApiSuccessAction<typeof FETCH_FORMS_SUCCESS, {}, FormDefinition[]>;

type FetchFormsErrorAction = ApiErrorAction<typeof FETCH_FORMS_ERROR, {}>;

export type HomeScreenAction =
  | CreateFormAction
  | CreateFormSuccessAction
  | CreateFormErrorAction
  | FetchFormsAction
  | FetchFormsSuccessAction
  | FetchFormsErrorAction;

export const fetchForms = () => get<ReduxState, any>(`${Config.apiBaseUrl}/forms`, FETCH_FORMS);

export const createForm = (form: FormDefinition) =>
  post<ReduxState, any>(`${Config.apiBaseUrl}/forms`, CREATE_FORM, undefined, form);
