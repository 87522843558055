// @flow
/**
 * A set of helper methods for parsing the property trees generated by flow-runtime
 */
import _ from 'lodash';
import { ObjectTypeProperty, TypeAlias } from 'flow-runtime';

import flowTypes from '../flowTypes';

const getTypeValue = (typeProperty: ObjectTypeProperty | TypeAlias) => {
  // Currently we assume only one nesting level of TypeAlias.
  // If we need nested aliases, we'll need to update this
  const isTypeAlias = typeProperty.value.typeName === flowTypes.TypeAlias;
  return isTypeAlias ? typeProperty.value.type : typeProperty.unwrap();
};

/**
 * @param properties - an array of ObjectTypeProperty, representing a flow type
 * @param parentKeys - an array of keys of all parent properties, for use in recursive calls
 * @returns an array of property keys which are required for a given type
 * Multiple items in an array represent object nesting levels
 * e.g. ['foo', 'bar'] indicates { foo: { bar: value } } is required
 */
const requiredKeys = (properties: ObjectTypeProperty[], parentKeys: string[] = []): any[] =>
  properties
    .reduce((keys, p) => {
      const propertyValue = p.unwrap();
      if (propertyValue.typeName === flowTypes.ObjectType) {
        keys.push(...requiredKeys(propertyValue.properties, parentKeys.concat(p.key)));
        return keys;
      }
      const value = p.optional ? parentKeys : parentKeys.concat(p.key);
      keys.push(value);
      return keys;
    }, [])
    .filter((a) => !_.isEmpty(a));

const optionalObjectPropertyKeys = (properties: ObjectTypeProperty[], parentKeys: string[] = []): any[] =>
  properties
    .reduce((keys, p) => {
      const value = getTypeValue(p);
      if (value.typeName !== flowTypes.ObjectType) {
        return keys;
      }
      if (p.optional) {
        keys.push(parentKeys.concat(p.key));
      }
      keys.push(...optionalObjectPropertyKeys(value.properties, parentKeys.concat(p.key)));
      return keys;
    }, [])
    .filter((a) => !_.isEmpty(a));

/**
 *
 * @param properties - an array of ObjectTypeProperty, representing a flow type
 * @param initialValues - any initial values that should override the defaults for given types
 * @returns an object matching the specified type, with default values set up
 */
const defaultValues = (properties: ObjectTypeProperty[], initialValues: Object) =>
  _.merge(
    {},
    ...properties.map((p) => {
      const propertyValue = p.unwrap();
      switch (propertyValue.typeName) {
        case flowTypes.ObjectType:
          return { [p.key]: defaultValues(propertyValue.properties) };
        case flowTypes.StringLiteralType:
          return { [p.key]: propertyValue.value };
        case flowTypes.BooleanType:
          return { [p.key]: false };
        case flowTypes.ArrayType:
          return { [p.key]: [] };
        default:
          return {};
      }
    }),
    initialValues
  );

export { requiredKeys, defaultValues, getTypeValue, optionalObjectPropertyKeys };
