// @flow
import React, { Component } from 'react';
import { Button } from '@an/nova-form-components';
import Overlay from './Overlay';
import { type Environment, getFilteredEnvironmentLinks } from '../../constants/environments';

type Props = {|
  environment: Environment,
|};

type State = {
  isPopupVisible: boolean,
};

export class EnvironmentButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isPopupVisible: false,
    };
  }

  render() {
    const { isPopupVisible } = this.state;
    const { environment } = this.props;

    const filteredEnvironmentLinks = environment && getFilteredEnvironmentLinks(environment);

    return (
      <>
        <Button onClick={this.handleClick} label="Select Environment" type="button" />
        <Overlay
          modalStyle={{
            content: { width: '25%', margin: '0 auto', height: 'fit-content' },
          }}
          visible={isPopupVisible}
          onClose={this.handleClosePopup}
        >
          <h3>NOVA Forms Admin Site Environments</h3>

          {filteredEnvironmentLinks &&
            filteredEnvironmentLinks.length > 0 &&
            filteredEnvironmentLinks.map(({ url, displayValue }) => (
              <div className="environment-link" key={displayValue}>
                <a target="_blank" rel="noopener noreferrer" href={url}>{`${displayValue} NOVA Forms Admin Site`}</a>
                <br />
              </div>
            ))}
        </Overlay>
      </>
    );
  }

  handleClick = () => {
    this.setState({ isPopupVisible: true });
  };

  handleClosePopup = () => {
    this.setState({ isPopupVisible: false });
  };
}
